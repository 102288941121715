import "./explorerContentView.scss";

import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";

import { TrackingHelper } from "../../../tools/trackingHelper";
import { SnowplowDataLayerEventName } from "../../../typings/snowplow";
import { PageExplorerContent, Widget } from "../../../utils/rtbf/models";
import { SearchKeyboard } from "../../views/keyboard/searchKeyboardView";
import { ExplorerTextBoxView } from "../../views/textbox/textboxView";
import { ContentView } from "../contentView";

enum ExplorerElement {
  textbox = "textbox",
  keyboard = "keyboard",
}

export class ExplorerContentView extends ContentView {
  private _textToSearch$ = new DS.Listenable("");
  private _displaySearch: (results: Widget[]) => void;
  private _searchUrl;
  protected _loaderElt;

  constructor(content: PageExplorerContent, displaySearch: (results: Widget[]) => void) {
    super(content.id, "explorerContentView");
    this._displaySearch = displaySearch;
    this.persistency = DS.IViewPersistency.static;
    this._searchUrl = content.searchForm.contentPath;
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "explorerTitle",
      innerText: content.title,
    });

    this._loaderElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "spinner",
    });

    this.delegate = DS.createListComponent({
      id: "explorerWidget",
      className: "explorerWidget",
      modelSource$: new DS.ModelSource([ExplorerElement.textbox, ExplorerElement.keyboard]),
      viewFactory: item => {
        switch (item) {
          case ExplorerElement.textbox:
            return new ExplorerTextBoxView(this._textToSearch$, content.searchForm.placeholder);
          case ExplorerElement.keyboard:
            return new SearchKeyboard(this._textToSearch$);
        }
      },
      scrollingMode: {
        type: DS.ScrollingType.page,
        horizontal: true,
      },
    });

    this._textToSearch$.didChange(async value => {
      if (value.trim().length >= 2) {
        this.displayLoader(true);
        this._displaySearch(await APIAuvio.search(value, this._searchUrl));
        this.displayLoader(false);
        TrackingHelper.track({
          event_name: "search",
          gtag: {
            search_term: value,
            submission: undefined,
          },
        });
        void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.search, {
          search_term: value.trim(),
        });
      }
    }, this);

    this.displayLoader(false);
  }

  displayLoader(show: boolean) {
    this._loaderElt.style.opacity = show ? "1" : "0";
  }
}
