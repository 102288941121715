import "./widgetView.scss";

import { DS } from "~/libs";
import { navigationStack } from "~/main";
import { GenericPage } from "~/pages/generic/genericPage";

import { castToNumber } from "../../tools/numberUtils";
import { TrackingHelper } from "../../tools/trackingHelper";
import {
  DataLayerEventWidgetClick,
  EventWithCardData,
  EventWithPageLocationData,
  EventWithWidgetData,
  SnowplowDataLayerEventName,
} from "../../typings/snowplow";
import {
  CategoryCard,
  ChannelCard,
  CtaCard,
  EmptyCard,
  FavoriteCard,
  GuestCard,
  MediaCard,
  MediaPremiumCard,
  PlayHistoryCard,
  ProductCard,
  ProgramCard,
  PromoBoxCard,
  QuickLinkCard,
  RadioLiveCard,
  TabListCard,
  TrackingData,
  TvLiveCard,
  Widget,
  WidgetBanner,
  WidgetMediaTrailer,
} from "../../utils/rtbf/models";
import { AcceptsMouseFocusView } from "../views/common/mouseSupport/acceptsMouseFocusView";

type Card = (
  | ProgramCard
  | RadioLiveCard
  | ProductCard
  | MediaCard
  | MediaPremiumCard
  | TvLiveCard
  | CategoryCard
  | ChannelCard
  | PromoBoxCard
  | TabListCard
  | QuickLinkCard
  | FavoriteCard
  | PlayHistoryCard
  | CtaCard
  | GuestCard
  | EmptyCard
  | MediaPremiumCard
  | WidgetBanner
  | WidgetMediaTrailer
) & { order?: number };

/**
 * All widget views should extends from this view.
 * This allow the GenericPage to know each items of
 * every widgets.
 */
export abstract class WidgetView extends AcceptsMouseFocusView {
  modelSource!: DS.IModelSource<Card | WidgetBanner | WidgetMediaTrailer | Widget | Widget[]>;

  protected _list?: DS.IListComponent<any, any>;
  id: string;
  private _gtag: Partial<TrackingData>;
  private _snowplowTrackingData: EventWithWidgetData & EventWithPageLocationData;
  /* NOTE: Code kept if client change is mind after backend can't handle the events load
  private _cardShownTrackingData: {
    timestamp: number;
    indexes: number[];
  } = {
    timestamp: Date.now(),
    indexes: [],
  };
  */

  constructor(id: string, classname: string) {
    super(id, "widget " + classname);
    this.id = id;
    this._gtag = {
      widget_id: id,
      widget_title: "",
      widget_type: undefined,
      widget_order: 0,
    };

    this._snowplowTrackingData = {
      widget_id: "-1",
      widget_title: "",
      widget_order: -1,
      widget_type: "",
      page_location: "",
      page_referrer: "",
    };
  }

  protected get snowplowTrackingData(): EventWithWidgetData & EventWithPageLocationData {
    return this._snowplowTrackingData;
  }

  protected _addSubtitleIfAvailable(widget: Widget) {
    if (widget.subtitle !== null && widget.subtitle !== "") {
      this.rootElement.classList.add("subtitle");
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "widgetSubtitle",
        innerText: widget.subtitle,
      });
    }
  }

  /**
   * Set analytics info for GTAG and Snowplow
   * @param widget The widget
   */
  protected _setAnalytics(widget: Widget) {
    const widgetOrder = this._widgetOrder(widget);
    this._gtag = {
      widget_id: widget.id,
      widget_title: widget.title,
      widget_order: widgetOrder,
      widget_type: widget.type,
    };

    this._snowplowTrackingData = {
      ...this._snowplowTrackingData,
      widget_id: widget.widgetId === undefined || widget.widgetId === null ? "-1" : widget.widgetId,
      widget_title: widget.title,
      widget_order: widgetOrder,
      widget_type: widget.type,
      page_location: TrackingHelper.getCurrentPageUrl(),
      page_referrer: TrackingHelper.getPageReferrerUrl(),
    };
  }

  private _widgetOrder(widget: Widget): number {
    if (widget.index === undefined) return -1;
    if (navigationStack.topPage instanceof GenericPage && navigationStack.topPage.hasTitle) return widget.index - 1;
    return widget.index;
  }

  protected _updateAnalytics({
    newGtagData,
    newSnowplowTrackingCardData,
  }: {
    newGtagData?: Partial<TrackingData>;
    newSnowplowTrackingCardData?: Partial<EventWithCardData>;
  }) {
    if (newGtagData) {
      this._gtag = {
        ...this._gtag,
        ...newGtagData,
      };
    }

    if (newSnowplowTrackingCardData) {
      this._snowplowTrackingData = {
        ...this._snowplowTrackingData,
        ...newSnowplowTrackingCardData,
      };
    }
  }

  protected _analyticsOnLeft() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "previous",
      },
    });
  }

  protected _analyticsOnRight() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "next",
      },
    });
  }

  protected _analyticsOnSelect() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "click",
        area: "frame",
      },
    });

    this._snowplowWidgetClick(this._isCardCTA() === false ? "card" : "voir-tout");
  }

  protected _analyticsOnUpDown() {
    TrackingHelper.track({
      event_name: "widget_interaction",
      gtag: {
        ...this._gtag,
        action: "scroll",
      },
    });
  }

  /**
   * Tracks card-related analytics by sending events to gtag and/or Snowplow.
   *
   * Depending on the provided parameters, this method:
   * - Sends a gtag tracking event if `gtag` data is provided.
   * - Sends a Snowplow event using either mapped `card` data or directly supplied `customSnowplowData`.
   *
   * The `customSnowplowData` parameter is used when the data to be sent to Snowplow is too specific to be derived from the card object itself, allowing for more granular control over the event data.
   *
   * @param {Object} params - The parameters for tracking analytics.
   * @param {Partial<TrackingData>} [params.gtag] - Data for a gtag tracking event.
   * @param {Card} [params.card] - A card object to map into Snowplow-compatible data.
   * @param {EventWithCardData} [params.customSnowplowData] - Custom pre-mapped data for a Snowplow event when card data is not sufficient.
   */
  protected _trackCardAnalytics({
    gtag,
    card,
    customSnowplowData,
  }: {
    gtag?: Partial<TrackingData>;
    card?: Card;
    customSnowplowData?: EventWithCardData;
  }) {
    if (gtag) {
      TrackingHelper.track({
        event_name: TrackingHelper.pageType === "EXPLORER" ? "search_select" : "card_interaction",
        gtag: {
          ...this._gtag,
          action: "click",
          area: "frame",
          ...gtag,
        },
      });
    }

    // Send a widget_click event
    if (card || customSnowplowData) this._analyticsOnSelect();

    if (card) {
      const mappedData = this._mapCardToSnowplowData(card);
      this._cardClickTracking(mappedData);
      return;
    }

    if (customSnowplowData) {
      this._cardClickTracking(customSnowplowData);
    }

    return;
  }

  protected _analyticsTrailer() {
    TrackingHelper.track({
      event_name: "widget_trailer_action",
      gtag: {
        ...this._gtag,
        action: "call_to_action",
        area: "button",
      },
    });
  }

  onShown() {
    TrackingHelper.track({
      event_name: "widget_shown",
      gtag: {
        ...this._gtag,
        action: "view",
      },
    });

    void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.widget_shown, {
      ...this._snowplowTrackingData,
    });
  }

  onNav(key: DS.Keys) {
    switch (key) {
      case DS.Keys.down:
      case DS.Keys.up:
        this._analyticsOnUpDown();
        break;
      case DS.Keys.left:
        this._analyticsOnLeft();
        break;
      case DS.Keys.right:
        this._analyticsOnRight();
        break;
    }
    return false;
  }

  /**
   * Sends a Snowplow card_click event with the specified data.
   *
   * @param {EventWithCardData} data - The card data to include in the Snowplow event.
   */
  private _cardClickTracking(data: EventWithCardData): void {
    void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.card_click, {
      ...this._snowplowTrackingData,
      ...data,
    });
  }

  /**
   * Maps a card object to Snowplow-compatible event data.
   *
   * The mapping extracts and validates key fields from the card object,
   * including `card_id`, `card_order`, and `card_type`, ensuring they
   * conform to the expected Snowplow data format.
   *
   * @param {Card} card - The card object to map.
   * @returns {EventWithCardData} The mapped Snowplow event data.
   */
  private _mapCardToSnowplowData(card: Card): EventWithCardData {
    const parsedData: EventWithCardData = {
      card_id: castToNumber(card.id),
      card_order: card.order ?? -1,
      card_type: "",
    };

    if ("resourceType" in card) {
      switch (card.resourceType) {
        case "RADIO_LIVE":
        case "HISTORY":
          parsedData.card_id = castToNumber(card.mediaId);
          break;
        case "CHANNEL":
        case "CATEGORY":
          parsedData.card_id = castToNumber(card.cardId);
          break;
      }

      parsedData.card_type = card.resourceType ?? "";
      return parsedData;
    }

    // Promobox
    if ("promoType" in card) {
      parsedData.card_id = castToNumber(card.mediaId);
      parsedData.card_type = card.promoType ?? "";
      return parsedData;
    }

    // Banner
    if ("type" in card) {
      parsedData.card_type = card.type ?? "";
      return parsedData;
    }

    if ("label" in card) {
      parsedData.card_type = card.label ?? "";
      return parsedData;
    }

    return parsedData;
  }

  /**
   * Search widget selected card and return if it's a CTA or not
   */
  private _isCardCTA(): boolean {
    const card = this._list?.modelFromIndex(this._list?.focusedIndex$.value);
    return card?.resourceType === "CTA";
  }

  /**
   * Determines if the widget is empty or if the user is not logged in.
   *
   * This means that the widget contains a card with resourceType “EMPTY”
   * to describe empty content, or “GUEST” if the user is not logged in.
   * @param {DS.IListComponent<Card, DS.IView>} list The cards list
   * @returns {Boolean} True if the widget is empty, false otherwise
   */
  protected isWidgetEmpty(list: DS.IListComponent<Card, DS.IView>): boolean {
    const firstItemModel = list.modelFromIndex(0);

    if (firstItemModel === undefined) return false;

    if ("resourceType" in firstItemModel) {
      switch (firstItemModel.resourceType) {
        case "EMPTY":
        case "GUEST":
          return true;
      }
    }

    return false;
  }

  /**
   * Send snowplow card_shown events and widget_click slide actions
   */
  protected _snowplowTracking(
    range: DS.IListComponent<Card, DS.IView>["visibleRange$"]["value"],
    list: DS.IListComponent<Card, DS.IView>,
    previousRange?: DS.IListComponent<Card, DS.IView>["visibleRange$"]["value"]
  ) {
    /* NOTE: Code kept if client change is mind after backend can't handle the events load
    // Reset the list of already sent card_shown events if we detect a page change
    const isNewPageSinceWidgetCreation = TrackingHelper.pageLoadTimestamp > this._cardShownTrackingData.timestamp;
    if (isNewPageSinceWidgetCreation) {
      this._cardShownTrackingData.indexes = [];
      this._cardShownTrackingData.timestamp = Date.now();
    }
    */

    if (range.first === undefined || range.last === undefined) return;

    // Handle card shown tracking
    for (let i = range.first; i <= range.last; i++) {
      /* NOTE: Code kept if client change is mind after backend can't handle the events load
      const isEventAlreadySent = this._cardShownTrackingData.indexes.indexOf(i) !== -1;
      if (isEventAlreadySent) continue;
      */

      const card = list.modelFromIndex(i);
      if (card === undefined) continue;
      // TODO: Filter card types ?
      const mappedData = this._mapCardToSnowplowData(card);

      void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.card_shown, {
        ...this._snowplowTrackingData,
        ...mappedData,
        card_order: i,
      });

      /* NOTE: Code kept if client change is mind after backend can't handle the events load
      this._cardShownTrackingData.indexes.push(i);
      */
    }

    // Handle widget_click slide actions
    if (previousRange?.first !== undefined && range?.first !== undefined) {
      const isSlideRight = range.first > previousRange.first;
      void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.widget_click, {
        ...this._snowplowTrackingData,
        action: isSlideRight ? "slide-right" : "slide-left",
      });
    }
  }

  protected _snowplowWidgetClick(action: DataLayerEventWidgetClick["action"]) {
    void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.widget_click, {
      ...this.snowplowTrackingData,
      action,
    });
  }

  /**
   * Prepare data and sends a Snowplow card_shown event with the specified data.
   *
   * @param {Card} card - The card shown
   */
  protected _snowplowCardShown(card: Card) {
    void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.card_shown, {
      ...this._snowplowTrackingData,
      ...this._mapCardToSnowplowData(card),
    });
  }
}
