import "./channelWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GenericPage } from "../../../pages/generic/genericPage";
import { ChannelCard, Widget, WidgetChannelList } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { WidgetView } from "../widgetView";

class ChannelTileView extends AcceptsMouseFocusView {
  constructor(channel: ChannelCard) {
    super(channel.id, "tile");

    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });

    if (channel.logo !== null && channel.logo.dark !== null) {
      cardContainer.style.backgroundImage = `url(${channel.logo.dark.png})`;
    } else {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: cardContainer,
        className: "tileTitle",
        innerText: channel.label,
      });
    }
  }
}

type WidgetModel = ChannelCard;

/**
 * View title to display missing widget during development
 */
export class ChannelWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;
  protected _list: DS.IListComponent<WidgetModel, ChannelTileView>;

  constructor(widget: Widget) {
    super(widget.id, "channelWidget");
    this._setAnalytics(widget);
    // Widget title
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });
    this._addSubtitleIfAvailable(widget);
    // Widget list of content
    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget))),
        viewFactory: channel => new ChannelTileView(channel),
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          Log.app.info("This card is selected: ", card);
          navigationStack.pushPage(new GenericPage(card.path));
          super._trackCardAnalytics({
            gtag: {
              card_order: index,
              channel_id: card.id,
              channel_name: card.label,
            },
            card: { ...card, order: index },
          });
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
        list.visibleRange$.didChange(
          (range, previousRange) => this._snowplowTracking(range, this._list, previousRange),
          this,
          true
        );
      }
    );
  }

  /**
   * Fetch channel list and return it, with a CTA if available, if contentPath is defined
   *
   * @param {Widget} widget
   * @return {Promise<ChannelCard[]>} List of items
   */
  private async _content(widget: Widget): Promise<WidgetModel[]> {
    try {
      return widget.contentPath !== undefined
        ? (await APIAuvio.widget(widget.contentPath, WidgetChannelList)).content
        : [];
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }
}
