import "./heroWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";
import { GenericPage } from "~/pages/generic/genericPage";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { CtaCard, ProgramCard, Widget, WidgetHeroList } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { MoreContentTile } from "../../tiles/moreContent/moreContent";
import { WidgetView } from "../widgetView";

class HeroTileView extends AcceptsMouseFocusView {
  constructor(hero: ProgramCard) {
    super(hero.id, "herotile");
    const tileElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
      style: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: tileElement,
      className: "heroBg",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: tileElement,
      className: "heroImg",
      style: {
        backgroundImage: `url(${hero.hero?.xl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
  }
}

type WidgetModel = CtaCard | ProgramCard;
/**
 * Category widget, built by the GenericPage.
 * @param widget The category widget.
 */
export class HeroWidgetView extends WidgetView {
  focusTracker?: FocusTracker;
  protected _list: DS.IListComponent<WidgetModel, HeroTileView | MoreContentTile>;

  constructor(widget: Widget) {
    super(widget.id, "heroWidget");
    this._setAnalytics(widget);
    // Widget list of content
    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget))),
        viewFactory: card => {
          switch (card.resourceType) {
            case "PROGRAM":
              return new HeroTileView(card);
            case "CTA":
              return new MoreContentTile(card);
          }
        },
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          if (card.resourceType === "PROGRAM") {
            super._trackCardAnalytics({
              gtag: {
                card_order: index,
                media_id: card.id,
                media_title: card.title ?? "",
                media_type: "vod",
                broadcast_title: card.title ?? "",
              },
              card: { ...card, order: index },
            });
          }
          navigationStack.pushPage(new GenericPage(card.path));
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer", { style: "hero" });
        this.collectRelease(this.focusTracker.onRelease);
        list.visibleRange$.didChange(
          (range, previousRange) => this._snowplowTracking(range, this._list, previousRange),
          this,
          true
        );
      }
    );
  }

  /**
   * Fetch hero list and return it, with a CTA if available, if contentPath is defined
   * Otherwise it returns the content stored in the widget object in the constructor (used for search)
   *
   * @param {Widget} widget
   * @return {Promise<(CtaCard | ProgramCard)[]>} List of items
   */
  private async _content(widget: Widget): Promise<WidgetModel[]> {
    try {
      const content: (CtaCard | ProgramCard)[] = (
        widget.contentPath !== undefined
          ? await APIAuvio.widget(widget.contentPath, WidgetHeroList)
          : WidgetHeroList.parse(widget)
      ).content;
      if (widget.cta !== undefined && widget.cta !== null) {
        content.push(widget.cta);
      }
      return content;
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }
}
