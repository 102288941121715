/* eslint-disable @typescript-eslint/naming-convention */
export enum SnowplowPlayerEventName {
  abort = "abort",
  ended = "ended",
  init = "init",
  pause = "pause",
  recommendation_click = "recommendation_click",
  resume = "resume",
  seeking = "seeking",
  sought = "sought",
  start = "start",
}

export enum SnowplowDataLayerEventName {
  button_click = "button_click",
  card_click = "card_click",
  card_shown = "card_shown",
  navigation_click = "navigation_click",
  page_load = "page_load",
  search = "search",
  widget_click = "widget_click",
  widget_shown = "widget_shown",
}

const snowplowPlayerEventVersions: { [key in SnowplowPlayerEventName]: string } = Object.fromEntries(
  Object.values(SnowplowPlayerEventName).map(event => [event, "1-0-2"])
) as { [key in SnowplowPlayerEventName]: string };

const snowplowDataLayerEventVersions: { [key in SnowplowDataLayerEventName]: string } = Object.fromEntries(
  Object.values(SnowplowDataLayerEventName).map(event => [event, "1-0-1"])
) as { [key in SnowplowDataLayerEventName]: string };

export const SNOWPLOW_EVENT_VERSIONS: { [key in SnowplowEventNames]: string } = {
  ...snowplowPlayerEventVersions,
  ...snowplowDataLayerEventVersions,
  button_click: "1-0-3",
};

// Shared types
type DeviceType = "desktop" | "mobile" | "tablet" | "smarttv";
type PlatformType = "site" | "app";
type ContextType = "media" | "programme";
type UserIdType = string | "nc";

export type EventWithoutAdditionalData = Record<string, never>;

export type EventWithPageLocationData = {
  page_location: string;
  page_referrer: string;
};

type EventWithEnvironmentData = {
  device: DeviceType;
  platform: PlatformType;
  user_agent: string;
};

type EventWithAppVersion = {
  version: string;
};

export type EventWithWidgetData = {
  widget_id: string;
  widget_title: string;
  widget_order: number;
  widget_type: string;
};

export type EventWithCardData = {
  card_id: number;
  card_order: number;
  card_type: string;
};

type EventWithContextData = {
  context: string;
  context_type: ContextType;
};

type EventWithMediaData = {
  media_id: string;
};

type EventWithOffsetData = {
  offset: number;
};

// Common event parameters (applicable to all events)
export type SnowplowBaseEventParams = {
  timestamp: number;
  user_id: UserIdType;
};

// Common parameters for player-related events
export type SnowplowBasePlayerEventParams = EventWithMediaData & {
  session_id: string;
};

// Common parameters for auvio data layer-related events
export type SnowplowBaseDataLayerEventParams = EventWithEnvironmentData &
  EventWithAppVersion & {
    screen_name: string;
    event_name: string;
  };

// Auvio Data Layer Events
export type DataLayerEventButtonClick = EventWithContextData &
  Pick<EventWithPageLocationData, "page_location"> & {
    button_title: string;
  };

export type DataLayerEventCardClick = EventWithWidgetData & EventWithCardData & EventWithPageLocationData;

export type DataLayerEventCardShown = EventWithWidgetData & EventWithCardData & EventWithPageLocationData;

export type DataLayerEventNavigationClick = EventWithPageLocationData & {
  menu_title: string;
};

export type DataLayerEventPageLoad = EventWithPageLocationData;

export type DataLayerEventSearch = {
  search_term: string;
};

export type DataLayerEventWidgetClick = EventWithWidgetData &
  EventWithPageLocationData & {
    action: "card" | "slide-left" | "slide-right" | "voir-tout";
  };

export type DataLayerEventWidgetShown = EventWithWidgetData & EventWithPageLocationData;

// Player events
export type PlayerEventAbort = EventWithOffsetData;

export type PlayerEventEnded = EventWithoutAdditionalData;

export type PlayerEventInit = EventWithContextData &
  EventWithPageLocationData &
  EventWithEnvironmentData &
  EventWithAppVersion & {
    is_live: boolean;
    is_audioonly: boolean;
    is_highlight: boolean;
    pseudo_user_id: string;
    client_ip: string;
    product: "Auvio" | "Actus" | "Autre";
  };

export type PlayerEventPause = EventWithOffsetData;

export type PlayerEventRecommendationClick = {
  recommended_media_id: string;
};

export type PlayerEventResume = EventWithOffsetData;

export type PlayerEventSeeking = EventWithOffsetData;

export type PlayerEventSought = EventWithOffsetData;

export type PlayerEventStart = EventWithOffsetData;

/**
 * Maps Snowplow event names to their corresponding parameter types.
 * This helps enforce typing and consistency when sending events.
 */
export type SnowplowEventsMapping = {
  [SnowplowPlayerEventName.abort]: PlayerEventAbort;
  [SnowplowPlayerEventName.ended]: PlayerEventEnded;
  [SnowplowPlayerEventName.init]: PlayerEventInit;
  [SnowplowPlayerEventName.pause]: PlayerEventPause;
  [SnowplowPlayerEventName.recommendation_click]: PlayerEventRecommendationClick;
  [SnowplowPlayerEventName.resume]: PlayerEventResume;
  [SnowplowPlayerEventName.seeking]: PlayerEventSeeking;
  [SnowplowPlayerEventName.sought]: PlayerEventSought;
  [SnowplowPlayerEventName.start]: PlayerEventStart;

  [SnowplowDataLayerEventName.button_click]: DataLayerEventButtonClick;
  [SnowplowDataLayerEventName.card_click]: DataLayerEventCardClick;
  [SnowplowDataLayerEventName.card_shown]: DataLayerEventCardShown;
  [SnowplowDataLayerEventName.navigation_click]: DataLayerEventNavigationClick;
  [SnowplowDataLayerEventName.page_load]: DataLayerEventPageLoad;
  [SnowplowDataLayerEventName.search]: DataLayerEventSearch;
  [SnowplowDataLayerEventName.widget_click]: DataLayerEventWidgetClick;
  [SnowplowDataLayerEventName.widget_shown]: DataLayerEventWidgetShown;
};

//Types
export type SnowplowEventNames = SnowplowPlayerEventName | SnowplowDataLayerEventName;

export type SnowplowPlayerEvents =
  | PlayerEventAbort
  | PlayerEventEnded
  | PlayerEventInit
  | PlayerEventPause
  | PlayerEventRecommendationClick
  | PlayerEventResume
  | PlayerEventSeeking
  | PlayerEventSought
  | PlayerEventStart;

export type SnowplowDataLayerEvents =
  | DataLayerEventButtonClick
  | DataLayerEventCardClick
  | DataLayerEventCardShown
  | DataLayerEventNavigationClick
  | DataLayerEventPageLoad
  | DataLayerEventSearch
  | DataLayerEventWidgetClick
  | DataLayerEventWidgetShown;

export type SnowplowEvents = SnowplowPlayerEvents | SnowplowDataLayerEvents;

export type SnowplowEventConfig = {
  e: string;
  p: "cnsl" | "tv";
  tv: string;
  ue_px: string;
};

export type SnowplowPayload = {
  [key: string]: string | number | boolean;
};
