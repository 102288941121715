import "./productPremiumWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";
import { PremiumHelper, PremiumManager } from "~/tools/premiumHelper";
import { ProductCard, Widget, WidgetProductPremiumList } from "~/utils/rtbf/models";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GenericPage } from "../../../pages/generic/genericPage";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { FavorisView } from "../../views/favorisView/favorisView";
import { StampView } from "../../views/stampView/stampView";
import { WidgetView } from "../widgetView";

/**
 * Category widget, built by the GenericPage.
 * @param widget The category widget.
 */
export class ProductPremiumWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;
  protected _list: DS.IListComponent<ProductCard, ProductPremiumTileView>;
  private _purchasedIds = new DS.Listenable<string[] | undefined>(undefined);

  constructor(widget: Widget) {
    super(widget.id, "productPremiumWidget premiumFocus");
    void this._getPurchasedIds();
    this._setAnalytics(widget);
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });

    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget))),
        viewFactory: productPremium => new ProductPremiumTileView(productPremium, this._purchasedIds),
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          super._trackCardAnalytics({
            gtag: {
              card_order: index,
              product_id: card.id,
              product_name: card.label,
            },
            card: { ...card, order: index },
          });

          if (card.path != null) {
            navigationStack.pushPage(new GenericPage(card.path));
          }

          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
        list.visibleRange$.didChange(
          (range, previousRange) => this._snowplowTracking(range, this._list, previousRange),
          this,
          true
        );
      }
    );

    this.modelSource.isComplete$.willChange(
      () => {
        if (this.modelSource.value.length < 4) {
          const arrows = this.rootElement.getElementsByClassName("mouseSupportArrow");
          if (arrows !== undefined && arrows !== null && arrows.length > 0) {
            for (let i = 0; i < arrows.length; i++) {
              const arrow = arrows[i] as HTMLElement;
              arrow.style.opacity = "0";
            }
          }
        }
      },
      this,
      true
    );
  }

  /**
   * Fetch ProductCard List if contentPath is defined
   * @param {Widget} widget The widget
   * @returns {Promise<ProductCard[]>} A promise returning an array of ProductCards
   */
  private async _content(widget: Widget): Promise<ProductCard[]> {
    try {
      return widget.contentPath !== undefined
        ? (await APIAuvio.widget(widget.contentPath, WidgetProductPremiumList)).content
        : [];
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }

  private async _getPurchasedIds(): Promise<void> {
    await PremiumHelper.updatePurchases("purchase");
    this._purchasedIds.value = await PremiumHelper.getPurchasedIds();
  }
}

class ProductPremiumTileView extends AcceptsMouseFocusView {
  constructor(productPremium: ProductCard, purchasedIds: DS.Listenable<string[] | undefined>) {
    super(productPremium.id, "tile");

    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    const tileElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileCover",
      style: {
        backgroundImage: `url(${productPremium.illustration?.s})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
      innerText: productPremium.label,
    });
    const tileSubtitle = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileSubtitle",
      innerText: productPremium.promotionShortLabel === undefined ? "" : productPremium.promotionShortLabel,
      style: {
        opacity: "0",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileType",
      innerText: productPremium.type,
    });
    const priceContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tilePriceContainer",
      style: {
        opacity: "0",
      },
    });
    if (productPremium.productOffering !== undefined) {
      const price = PremiumManager.priceFromProductOffering(productPremium.productOffering);
      if (price !== "") {
        DS.DOMHelper.createElement({
          tagName: "span",
          parent: priceContainer,
          className: "tilePrice",
          innerText: PremiumManager.priceFromProductOffering(productPremium.productOffering),
        });
        DS.DOMHelper.createElement({
          tagName: "span",
          parent: priceContainer,
          innerText: "/" + productPremium.durationLabel,
        });
      }
    }
    const subscribedEl = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "subscribed",
      innerText: t("generic.subscribed"),
      style: {
        opacity: "0",
      },
    });

    if (productPremium.stamp !== undefined) {
      tileElement.appendChild(new StampView(productPremium.stamp).rootElement);
    }

    tileElement.appendChild(new FavorisView(productPremium.id).rootElement);

    purchasedIds.didChange(
      purchasedIds => {
        if (purchasedIds === undefined) return;
        const isPurchased =
          purchasedIds.findIndex(purchaseId => purchaseId === productPremium.productOffering?.productOfferingId) !== -1;

        if (isPurchased === true) {
          subscribedEl.style.opacity = "1";
        } else {
          priceContainer.style.opacity = "1";
          if (PremiumHelper.isFreePeriodConsumed(productPremium.productOffering?.productOfferingId) === false) {
            tileSubtitle.style.opacity = "1";
          }
        }
      },
      this,
      true
    );
  }
}
