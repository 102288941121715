import "./tabWidgetView.scss";

import { DS } from "dslib-tv";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { ProgressBarView } from "~/components/views/common/progressBar/progressBarView";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { PaginatedModelSource } from "~/datas/paginatedModelSource";

import { Config } from "../../../config";
import { PlayerPage } from "../../../pages/player/playerPage";
import { PlayHistoryHelper } from "../../../tools/playHistoryHelper";
import {
  MediaCard,
  Widget,
  WidgetMediaCardOnlyList,
  WidgetTabList,
  WidgetTabListPaginatedResponse,
} from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { ContentMetaView } from "../../views/content/contentMetaView/contentMetaView";
import { FavorisView } from "../../views/favorisView/favorisView";
import { WidgetView } from "../widgetView";

enum TabComponent {
  BUTTONS = "buttons",
}

class MediaTileView extends AcceptsMouseFocusView {
  private _progressBarView: ProgressBarView | undefined;

  constructor(media: MediaCard) {
    super(media.id, "tileTabCard");

    const cardContainerElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileCardContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "tileCover",
      style: {
        backgroundImage: `url(${media.illustration?.s})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "tileTitle",
      innerText: media.title,
    });
    if (media.subtitle !== undefined && media.subtitle !== "") {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: cardContainerElt,
        className: "tileSubtitle",
        innerText: media.subtitle,
      });
    }
    const metaContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "metaContainer",
    });
    if (media.description !== null) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: cardContainerElt,
        className: "description",
        innerText: media.description,
      });
    }

    metaContainer.appendChild(
      new ContentMetaView({
        csaRating: media.rating ?? null,
        hasMultilingual: media.hasMultilingualVersions,
        hasSubtitle: media.hasSubtitles,
        hasAudio: media.hasAudioDescriptions,
        duration: media.duration,
        date: media.publishedFrom,
        textDuration: media.releaseDate ?? "",
      }).rootElement
    );
    cardContainerElt.appendChild(new FavorisView(media.id).rootElement);

    const currentOffset$ = PlayHistoryHelper.getCurrentOffset(media.id);
    currentOffset$.didChange(
      currentOffset => {
        if (currentOffset === 0) return;
        if (this._progressBarView === undefined) {
          this._progressBarView = new ProgressBarView("media", media.duration);
          cardContainerElt.appendChild(this._progressBarView.rootElement);
        }
        this._progressBarView.updateCurrenOffset(currentOffset);
      },
      this,
      true
    );
  }
}

class TabSubWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;
  private _modelSource: PaginatedModelSource<MediaCard>;
  protected _list: DS.IListComponent<MediaCard, MediaTileView>;

  constructor(cardsContentPath: string) {
    super("", "tabSubWidgetView");

    this.delegate = this._list = DS.createListComponent(
      {
        id: "",
        className: "tabSubWidgetList",
        modelSource$: (this._modelSource = APIAuvio.widgetPaginated(
          cardsContentPath,
          WidgetTabListPaginatedResponse,
          WidgetMediaCardOnlyList
        )),
        viewFactory: media => new MediaTileView(media),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        modelSourcePageLength: 24,
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          void PlayerPage.playAsset(card.id, card.type, "MEDIA", false, { rating: card.rating });
          super._trackCardAnalytics({
            card: { ...card, order: index },
          });
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "tileCardContainer");
        this.collectRelease(this._focusTracker.onRelease);
        list.visibleRange$.didChange(
          (range, previousRange) => this._snowplowTracking(range, this._list, previousRange),
          this,
          true
        );
      }
    );
  }
}

class TabButtonsTileView extends AcceptsMouseFocusView {
  constructor(tab: Widget) {
    super(tab.title, "buttonTabtile");
    const cardContainerElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    const itemsContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "itemsContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: itemsContainer,
      className: "tileTitle",
      innerText: tab.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: itemsContainer,
      className: "tileSubtitle",
      innerText: tab.subtitle,
    });
  }
}

class TabButtonsView extends AcceptsMouseFocusView {
  private _focusTracker?: FocusTracker;
  list;
  persistency = DS.IViewPersistency.cache;
  private _cardsContentPath$ = new DS.Listenable<string>("");
  private _emptyWidgetCallback;
  get cardsContentPath$(): DS.IListenable<string> {
    return this._cardsContentPath$;
  }

  constructor(contentPath: string, emptyWidgetCallback: () => void) {
    super("", "tabButtonsView");
    this._emptyWidgetCallback = emptyWidgetCallback;
    this.delegate = this.list = DS.createListComponent(
      {
        id: "",
        className: "tabButtonsList",
        modelSource$: new DS.ModelSource(this._content(contentPath)),
        viewFactory: tab => {
          return new TabButtonsTileView(tab);
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
        list.focusedView$.didChange(
          (oldView, newView) => {
            if (newView !== undefined) void this._focusManager(newView);
            if (oldView !== undefined) void this._focusManager(oldView, true);
            list.focusedView$;
          },
          this,
          true
        );
      }
    );
  }

  private _focusManager(value: DS.IView, addClass = false) {
    if (addClass === true) {
      value?.rootElement.querySelector(".cardContainer")?.classList.add("focusedTab");
    } else {
      value?.rootElement.querySelector(".cardContainer")?.classList.remove("focusedTab");
    }
  }

  /**
   * This function allow to use the widget.content inside a modelSource + and load the first  button data
   * @param contentPath The widget content path
   * @returns A promise with widget.content
   */
  private async _content(contentPath: string): Promise<Widget[]> {
    try {
      const widget = await APIAuvio.widget(contentPath, WidgetTabList);
      this._cardsContentPath$.value = widget.content[0]?.contentPath ?? "";
      if (widget.content.length === 0) this._emptyWidgetCallback();
      return widget.content;
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
    }
    return [];
  }
}

/**
 * View title to display missing widget during development
 */
export class TabWidgetView extends WidgetView {
  protected _list: DS.IListComponent<string, TabButtonsView | TabSubWidgetView>;
  private _buttonsView?: TabButtonsView;
  private _modelSource$ = new DS.ModelSource<string>([TabComponent.BUTTONS]);

  constructor(widget: Widget, emptyWidgetCallback: () => void) {
    super(widget.id, "tabWidget");

    this.delegate = this._list = DS.createListComponent({
      id: `${widget.id}/list`,
      className: "tabList",
      modelSource$: this._modelSource$,
      viewFactory: buttonsOrContentPath => {
        switch (buttonsOrContentPath) {
          case TabComponent.BUTTONS: {
            this._buttonsView = new TabButtonsView(widget.contentPath ?? "", emptyWidgetCallback);
            const unreg = this._buttonsView.cardsContentPath$.didChange(
              newContentPath => {
                if (newContentPath !== "") {
                  this._modelSource$.value = [TabComponent.BUTTONS, newContentPath];
                  unreg();
                }
              },
              this,
              true
            );
            return this._buttonsView;
          }
          default:
            return new TabSubWidgetView(buttonsOrContentPath);
        }
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport && { focusRange: "visible" },
      onSelect: (item, index) => {
        super._trackCardAnalytics({
          gtag: {
            card_order: index,
          },
        });
        if (item === TabComponent.BUTTONS) {
          const model = this._buttonsView?.list.modelFromIndex(this._buttonsView.list.focusedIndex$.value);
          if (model?.contentPath !== undefined) {
            this._modelSource$.value = [TabComponent.BUTTONS, model.contentPath];
          }
          return true;
        }
        return false;
      },
    });
  }
}
