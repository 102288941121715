import "./programContentView.scss";

import { DS } from "~/libs";
import { millisecondsUntilDate } from "~/tools/time";

import { Config } from "../../../config";
import { PlayerPage } from "../../../pages/player/playerPage";
import { PlayHistoryHelper } from "../../../tools/playHistoryHelper";
import { TrackingHelper } from "../../../tools/trackingHelper";
import { SnowplowDataLayerEventName } from "../../../typings/snowplow";
import { EmbedType, MediaCard, MediaPremiumCard, PageProgramContent, TvLiveCard } from "../../../utils/rtbf/models";
import {
  ClassType,
  ContentButton,
  ContentButtonsContainerView,
  ContentButtonWidgetType,
} from "../../views/content/contentButtonView/contentButtonView";
import { ContentCategoryChannelView } from "../../views/content/contentCategoryChannelView/contentCategoryChannelView";
import { ContentDescriptionView } from "../../views/content/contentDescriptionView/contentDescriptionView";
import {
  ContentPlayLiveLargeButtonView,
  ContentPlayMediaLargeButtonView,
} from "../../views/content/contentPlayLargeButtonView/contentPlayLargeButtonView";
import { ContentSubtitleView } from "../../views/content/contentSubtitleView/contentSubtitleView";
import { ContentTitleView } from "../../views/content/contentTitleView/contentTitleView";

enum ProgramContentItem {
  buttonPlay,
  buttonPlayLive,
  categoryChannel,
  contentButtonsContainer,
  description,
  title,
  subtitle,
}

type ContentButtonWidgetView =
  | ContentCategoryChannelView
  | ContentTitleView
  | ContentSubtitleView
  | ContentPlayMediaLargeButtonView
  | ContentPlayLiveLargeButtonView
  | ContentDescriptionView
  | ContentButtonsContainerView;

export class ProgramContentView extends DS.View {
  private _list: DS.IListComponent<ProgramContentItem, ContentButtonWidgetView>;
  private _modelSource$ = new DS.ModelSource<ProgramContentItem>([]);
  private _classType: ClassType = ClassType.serie;
  private _content;
  private _media$ = new DS.Listenable<MediaCard | MediaPremiumCard | undefined>(undefined);
  private _playLargeButtonView: ContentPlayMediaLargeButtonView | undefined;
  private _playButtonType?: EmbedType;
  private _liveTimeoutID?: number = undefined;
  private _contentButtonsContainerView?: ContentButtonsContainerView;
  private _hasCurrentLive = false;
  private _delayPlayHistory = false;

  constructor(content: PageProgramContent) {
    super(content.id, "programContentView");

    this._content = content;
    this._updateModelSource(content.live);
    this._hasCurrentLive = content.live !== null && content.live !== undefined;

    this.delegate = this._list = DS.createListComponent({
      id: "programContentList",
      className: "programContentList",
      modelSource$: this._modelSource$,
      viewFactory: item => {
        switch (item) {
          case ProgramContentItem.categoryChannel:
            return new ContentCategoryChannelView(this._classType, content.channel?.label, content.category?.label);
          case ProgramContentItem.title:
            return new ContentTitleView(content.title);
          case ProgramContentItem.subtitle: {
            let subtitle = "";
            if (content.seasonCount !== null) {
              subtitle += `${content.seasonCount} ${
                content.seasonCount === 1 ? t("generic.season_title") : t("generic.seasons_title")
              } `;
            }
            if (content.videoCount !== null) {
              if (content.media?.type === "AUDIO") {
                subtitle += `${content.videoCount} ${
                  content.videoCount === 1 ? t("generic.content_title") : t("generic.contents_title")
                }`;
              } else {
                subtitle += `${content.videoCount} ${
                  content.videoCount === 1 ? t("generic.video_title") : t("generic.videos_title")
                }`;
              }
            }
            return new ContentSubtitleView(subtitle);
          }
          case ProgramContentItem.buttonPlay:
            return (this._playLargeButtonView = new ContentPlayMediaLargeButtonView(content.id, this._media$));
          case ProgramContentItem.buttonPlayLive:
            return new ContentPlayLiveLargeButtonView(content.id, content.live ?? undefined);
          case ProgramContentItem.contentButtonsContainer:
            return (this._contentButtonsContainerView = new ContentButtonsContainerView(
              this._buttons(),
              content,
              this._classType,
              this._hasCurrentLive
            ));
          case ProgramContentItem.description:
            return new ContentDescriptionView(content.description);
        }
      },
      scrollingMode: { type: DS.ScrollingType.elasticWindow, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
      onSelect: programContentItem => {
        const currentMediaOffset = PlayHistoryHelper.getCurrentOffset(this?._media$?.value?.id ?? "");

        const playButtonTitle = PlayHistoryHelper.getPlayButtonTitle(
          currentMediaOffset.value,
          this?._media$?.value?.type ?? ""
        );

        void TrackingHelper.snowplowEvent(SnowplowDataLayerEventName.button_click, {
          button_title: t(playButtonTitle, "fr"),
          context: this._content.id ?? "",
          context_type: this._content.pageType === "PROGRAM" ? "programme" : "media",
          page_location: TrackingHelper.getCurrentPageUrl(),
        });

        if (programContentItem === ProgramContentItem.buttonPlay) {
          if (this._media$.value === undefined) return false;

          void PlayerPage.playAsset(this._media$.value.id, this._media$.value.type, "MEDIA", false, {
            rating: this._media$.value.rating,
          });
          return true;
        } else if (programContentItem === ProgramContentItem.buttonPlayLive) {
          void PlayerPage.playAsset(content.live?.id ?? "", content.live?.type, "LIVE");
          return true;
        }
        return false;
      },
    });

    this._media$.didChange(
      media => {
        if (media === undefined) {
          this._updateFocus();
          return false;
        }
        // Avoid a focus glitch when the next media to play is retrieved on a program with a live in progress
        if (this._playButtonType === EmbedType.enum.LIVE) return false;

        this._contentButtonsContainerView?.updateButtons(this._buttons(), media);
        // The timeout is set to trigger the focus adjustment after the one on contentButtonsContainer when buttons are updated
        window.setTimeout(() => this._updateFocus(), 0);
      },
      this,
      true
    );
  }

  /**
   * Update the list of items of this view, with a live play button or a media play button
   * Update the model source, with a live play button or a media play button
   * @param {TvLiveCard?} live
   */
  private _updateModelSource(live?: TvLiveCard | null) {
    const items: ProgramContentItem[] = [];

    items.push(ProgramContentItem.categoryChannel);
    items.push(ProgramContentItem.title);
    items.push(ProgramContentItem.subtitle);
    if (live !== null && live !== undefined) {
      this._playButtonType = EmbedType.enum.LIVE;
      items.push(ProgramContentItem.buttonPlayLive);

      if (this._content.media !== null) {
        this._liveTimeoutID = window.setTimeout(() => {
          this._hasCurrentLive = false;
          this._updateModelSource();
        }, millisecondsUntilDate(new Date(live.scheduledTo)));
      }
    } else if (this._content.media !== null) {
      this._playButtonType = EmbedType.enum.MEDIA;
      items.push(ProgramContentItem.buttonPlay);
    } else {
      this._playButtonType = undefined;
    }
    items.push(ProgramContentItem.contentButtonsContainer);
    items.push(ProgramContentItem.description);

    this._modelSource$.value = items;
  }

  /**
   * Generate the list of buttons to show in the ContentButtonsContainerView
   * @returns {ContentButton[]}
   */
  private _buttons() {
    const buttons: ContentButton[] = [];
    let addStovButton = false;

    if (this._playButtonType === EmbedType.enum.MEDIA && this._media$.value !== undefined)
      addStovButton = PlayHistoryHelper.getCurrentOffset(this._media$.value?.id)?.value > 0;
    if (
      this._playButtonType === EmbedType.enum.LIVE &&
      this._hasCurrentLive === true &&
      this._content.live?.isAdReplacement !== true
    )
      addStovButton = true;

    if (addStovButton === true) {
      buttons.push({
        id: "StovContentButton",
        type: ContentButtonWidgetType.stovContentButton,
        content: this._content,
      });
    }
    buttons.push({
      id: "FavoriteContentButton",
      type: ContentButtonWidgetType.favoriteContentButton,
      content: this._content,
    });

    this._content?.additionalLinks?.forEach((additionalLink, index) => {
      buttons.push({
        id: `GenericContentButton${index}`,
        type: ContentButtonWidgetType.genericContentButton,
        genericLink: additionalLink,
      });
    });
    buttons.push({
      id: "InfoContentButton",
      type: ContentButtonWidgetType.infoContentButton,
      content: this._content,
    });

    return buttons;
  }

  /*
   * Update focus in the program content list
   * Check if programContentView is focused instead of the tabWidget to avoid breaking navigation
   */
  _updateFocus() {
    if (this.rootElement.classList.contains("focused") === true) {
      if (this._media$.value === undefined) {
        void this._list.setFocusOnId(ProgramContentItem.contentButtonsContainer.toString());
      } else {
        void this._list.setFocusOnId(ProgramContentItem.buttonPlay.toString());
      }
    }
  }

  async onShown() {
    if (this._content.media) {
      this._playLargeButtonView?.displayLoader(true);
      this._media$.value = undefined;
      // Add a delay to let the RedBee player analytics make an API query to update the offset of current media before querying the play history of the media
      this._delayPlayHistory === true && (await DS.delay(2000));
      this._media$.value = await PlayHistoryHelper.getPlayButtonMedia(this._content.id, this._content.media);
    }
    this._delayPlayHistory = true;
  }

  onRelease() {
    super.onRelease();
    this._liveTimeoutID !== undefined && window.clearTimeout(this._liveTimeoutID);
  }
}
