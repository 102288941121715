import "./trailerWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";
import { navigationStack } from "~/main";
import { GenericPage } from "~/pages/generic/genericPage";
import { PlayerPage } from "~/pages/player/playerPage";
import { isTizen8 } from "~/tools/deviceHelper";
import { playerAudio, PlayerState, playerVideo } from "~/tools/player";

import { Widget, WidgetMediaTrailer } from "../../../utils/rtbf/models";
import { TrailerPlayer } from "../../player/trailer/trailerPlayer";
import { WidgetView } from "../widgetView";

/**
 * Trailer tile class, represent all the tile widget trailer.
 */
class TrailerTileView extends AcceptsMouseFocusView {
  private _player: TrailerPlayer | undefined;
  private _trailer;
  private _focused = false;
  private _isVisible = true; // Initiated to avoid onShown not called on first apparition
  private _playVideoTimeout: number | undefined;

  constructor(trailer: WidgetMediaTrailer) {
    super(`trailer-${trailer.id}`, `tile ${isTizen8() === false ? "opacityAnimations" : ""}`);
    this._trailer = trailer;

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "focusTrackerStatic",
    });

    const text = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileDescription",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: text,
      className: "title",
      innerText: trailer.programTitle,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: text,
      className: "nbVideos",
      innerText: `${trailer.videoCount} ${
        trailer.videoCount === 1 ? t("generic.content_title") : t("generic.contents_title")
      }`,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: text,
      className: "subtitle",
      innerText: trailer.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: text,
      className: "description",
      innerText: trailer.subtitle,
    });
    if (trailer.illustration !== null) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "tileCover",
        style: {
          backgroundImage: `url(${trailer.illustration.xl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
      });
    } else {
      this.rootElement.classList.add("noIllustration");
    }

    // ____    __    ____  ___      .______      .__   __.  __  .__   __.   _______
    // \   \  /  \  /   / /   \     |   _  \     |  \ |  | |  | |  \ |  |  /  _____|
    //  \   \/    \/   / /  ^  \    |  |_)  |    |   \|  | |  | |   \|  | |  |  __
    //   \            / /  /_\  \   |      /     |  . `  | |  | |  . `  | |  | |_ |
    //    \    /\    / /  _____  \  |  |\  \----.|  |\   | |  | |  |\   | |  |__| |
    //     \__/  \__/ /__/     \__\ | _| `._____||__| \__| |__| |__| \__|  \______|
    //
    // playerVideo is a singleton and is state can change from another view.
    // Don't interact with player (play/pause) or other general component.
    // This warning is here because it created a bug on the player page when this view was loaded before opening it.
    playerVideo.state$.didChange(
      state => {
        if (!DS.DOMHelper.isInDOM(this.rootElement)) return;
        switch (state) {
          case PlayerState.PLAYING:
            if (this._focused === true) {
              this.rootElement.classList.add("videoPlaying");
            } else if (!(navigationStack.topPage instanceof PlayerPage)) {
              this._player?.pause();
            }
            break;
          case PlayerState.PAUSED:
          case PlayerState.IDLE:
          case PlayerState.ENDED:
            this.rootElement.classList.remove("videoPlaying");
            break;
        }
      },
      this,
      false
    );
  }

  private async _playVideo() {
    if (playerAudio.state$.value === PlayerState.PLAYING) return;
    if (this._player === undefined) {
      this.delegate = this._player = new TrailerPlayer(this._trailer.id);
    }
    if (this._player.isIdle()) {
      await this._player.load(this._trailer.mediaId);
    }
    // Check if it's still focused after loading
    if (this._focused) {
      this._player.play();
    }
  }

  private _debouncePlayVideo() {
    clearTimeout(this._playVideoTimeout);
    if (this._focused && !(navigationStack.topPage instanceof PlayerPage)) {
      this._playVideoTimeout = window.setTimeout(() => {
        if (this._focused === true) {
          void this._playVideo();
        }
        clearTimeout(this._playVideoTimeout);
      }, 2000);
    }
  }

  // This event can fire twice in a row and the condition before calling _playVideo is here to prevent a graphic bug
  onFocused() {
    if (this._focused === true) return;

    this._focused = true;
    void this._playVideo();
  }

  onNav(key: DS.Keys) {
    if (this._player !== undefined) {
      switch (key) {
        case DS.Keys.play:
          this._player.play();
          return true;
        case DS.Keys.pause:
          this._player.pause();
          return true;
        case DS.Keys.playPause:
          this._player.isPlaying() ? this._player.pause() : this._player.play();
          return true;
      }
    }
    return false;
  }

  onUnfocused() {
    this._focused = false;
    if (this._player !== undefined) this._player.pause();
  }

  onShown() {
    this._isVisible = true;

    if (this._focused === true) {
      this._debouncePlayVideo();
    }
  }

  onHidden() {
    if (this._isVisible === true) {
      this._player?.onRelease();
      this.rootElement.classList.remove("videoPlaying");
      this._isVisible = false;
    }
  }
}

/**
 * Trailer widget, built by the GenericPage.
 * @param widget The trailer widget.
 */
export class TrailerWidgetView extends WidgetView {
  private _trailer: WidgetMediaTrailer | undefined;

  constructor(widget: Widget) {
    super(widget.id, "trailerWidget widgetList");
    this._setAnalytics(widget);

    void this._fetchAndRender(widget.contentPath ?? "");
  }

  private async _fetchAndRender(contentPath: string) {
    this._trailer = await APIAuvio.widget(contentPath, WidgetMediaTrailer);
    this.delegate = new TrailerTileView(this._trailer);
    super._snowplowCardShown({ ...this._trailer, order: 0 });
  }

  onNav(key: DS.Keys) {
    switch (key) {
      case DS.Keys.select:
        return this._selectAction();
    }
    return false;
  }

  onMouseDown(point: DS.IPoint, target: HTMLElement) {
    return this._selectAction();
  }

  private _selectAction() {
    if (this._trailer === undefined) {
      return false;
    }
    super._trackCardAnalytics({
      gtag: {
        card_order: 0,
      },
      card: { ...this._trailer, order: 0 },
    });
    this._analyticsTrailer();
    navigationStack.pushPage(new GenericPage(this._trailer.path));
    return true;
  }

  // disabled navigation analytics for this widget
  protected _analyticsOnLeft() {}
  protected _analyticsOnRight() {}
  protected _analyticsOnUpDown() {}
}
