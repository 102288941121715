import "./u2cWidgetView.scss";

import { MediaTileView } from "~/components/tiles/media/mediaTileView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { GenericPage } from "~/pages/generic/genericPage";
import { PairingPageView } from "~/pages/pairing/pairingPage";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { CtaCard, EmptyCard, GuestCard, MediaCard, Widget } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { MoreContentTile } from "../../tiles/moreContent/moreContent";
import { FavorisSpecificView } from "../../views/FavorisSpecificView/FavorisSpecificView";
import { WidgetView } from "../widgetView";

/**
 *  U2C widget, built by the GenericPage.
 * @param widget The  widget.
 */
type U2CWidgetModelSource = MediaCard | CtaCard | GuestCard | EmptyCard;

export class U2CWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;
  private _widget;
  private _guestState;
  private _emptyState;
  protected _list: DS.IListComponent<U2CWidgetModelSource, MediaTileView | FavorisSpecificView | MoreContentTile>;

  constructor(widget: Widget) {
    super(widget.id, "u2cWidget");
    this._widget = widget;
    this._guestState = this._widget.states?.guest;
    this._emptyState = this._widget.states?.empty;
    this._setAnalytics(widget);
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });

    this._addSubtitleIfAvailable(widget);

    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget.contentPath ?? ""))),
        viewFactory: card => {
          switch (card.resourceType) {
            case "MEDIA":
              return new MediaTileView(card, "listSize");
            case "CTA":
              return new MoreContentTile(card);
            case "GUEST":
            case "EMPTY":
              return new FavorisSpecificView(card);
          }
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          super._trackCardAnalytics({
            gtag: {
              card_order: index,
            },
            card: { ...card, order: index },
          });
          switch (card.resourceType) {
            case "MEDIA":
              navigationStack.pushPage(new GenericPage(card.path));
              break;
            case "CTA":
              navigationStack.pushPage(new GenericPage(card.path));
              break;
            case "GUEST":
            case "EMPTY":
              if (card.cta?.action === "LOGIN") navigationStack.pushPage(new PairingPageView());
              if (card.cta?.action === "NAVIGATE") navigationStack.pushPage(new GenericPage(card.cta.path));
          }
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
        list.visibleRange$.didChange(
          (range, previousRange) => this._snowplowTracking(range, this._list, previousRange),
          this,
          true
        );
      }
    );
  }

  /**
   * This function return the modelSource according to those rules :
   * - Return the guest state if not connected
   * - Fetch items and return them, with a CTA if available
   *   if the list is empty display the empty state
   * - If previous conditions couldn't be met, return an empty array
   * @param contentPath The widget content path
   * @returns A promise with widget.content
   */
  private async _content(contentPath: string): Promise<U2CWidgetModelSource[]> {
    if (!APIGigyaOIDC.userInfo$.value && this._guestState) return [this._guestState];

    try {
      const cards = await APIAuvio.widgetU2C(contentPath);

      if (cards.length === 0 && this._emptyState) return [this._emptyState];
      if (this._widget.cta) {
        return [...cards, this._widget.cta];
      }

      return cards;
    } catch {
      return this._emptyState ? [this._emptyState] : [];
    }
  }
}
