/**
 * Converts a given value to a number if it represents a valid numeric value.
 *
 * - If the value is already a valid number, it is returned as-is.
 * - If the value is a string that represents a valid number (e.g., integers, decimals, negative numbers),
 *   it will be converted to a number.
 * - If the value is not a valid number or cannot be converted, it returns -1.
 *
 * @param {unknown} value - The value to be converted to a number.
 * @returns {number} - The converted number, or -1 if the conversion fails.
 *
 * @example
 * castToNumber(42); // 42
 * castToNumber("   -42.5   "); // -42.5
 * castToNumber("abc"); // -1
 * castToNumber(null); // -1
 * castToNumber(undefined); // -1
 */
export const castToNumber = (value: unknown): number => {
  if (typeof value === "number" && !Number.isNaN(value)) {
    return value;
  }

  if (typeof value === "string" && /^\s*-?\d+(\.\d+)?\s*$/.test(value)) {
    return Number(value.trim());
  }

  return -1;
};
