import { Config, env } from "~/datas";
import { DS } from "~/libs";
import { loadJS } from "~/tools/snippets/scripts";

export const loadElastic = async () => {
  await loadJS("./elastic_" + _APP_HASH_ + ".js");

  const platformType = DS.platform.type;
  const platform =
    platformType === DS.PlatformType.tizen
      ? "tizen"
      : platformType === DS.PlatformType.webos
        ? "lg"
        : platformType === DS.PlatformType.ps4
          ? "ps4"
          : platformType === DS.PlatformType.ps5
            ? "ps5"
            : "desktop";

  window.elasticApm?.init({
    serverUrl: Config().ELASTIC.apiServerUrl,
    environment: env() === "UAT" ? "u1" : "p1", // ("d1" pour les environnements de dev, "u1" pour les en UAT, "p1" pour la PROD)
    serviceName: `auvio-tv-${platform}`, // "tizen", "lg", "ps4" ou "ps5" en fonction de la plateforme
    serviceVersion: _APP_VERSION_,
    transactionSampleRate: 0.1,
    centralConfig: true,
    active: true,
    ignoreTransactions: [new RegExp("^(?!((?!.*gdd\.rtbf\.be).*(rtbf\.be|gigya\.com|exposure\.api\.redbee\.live))).*$")],
  });
};
