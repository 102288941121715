import { DS } from "dslib-tv";

import { deepCopyObject } from "../tools/objectHelper";

type Env = "UAT" | "PROD";

type Config = {
  RTBF: {
    authServerUrl: string;
    apiServerUrl: string;
    clientSecret: string;
    clientId: string;
    userAgent: string;
    apiVersion: string;
    platform: string;
    ramServerUrl: string;
    pairingUrl: string;
  };
  RTBFU2C: {
    apiServerUrl: string;
  };
  REDBEE: {
    customer: string;
    businessUnit: string;
    exposureBaseUrl: string;
    appName: string;
    muxKey: string;
  };
  GIGYA: {
    dataCenter: string;
    apiKey: string;
    clientId: string;
    clientSecret: string;
    pairingUrl: string;
  };
  DIDOMI: {
    authServerUrl: string;
    apiServerUrl: string;
    apiKey: string;
    apiSecretKey: string;
    organization_id: string;
    APIKey: string;
    NoticeId: string;
  };
  GTAG: {
    id: string;
    debug: boolean;
  };
  SNOWPLOW: {
    eventServerUrl: string;
    baseApplicationUrl: string;
  };
  ELASTIC: {
    apiServerUrl: string;
  };
};

function platformConfig() {
  switch (DS.platform.type) {
    case DS.PlatformType.webos:
      return {
        clientSecret: "pZ7IZNXzq3WGQ8dvZrDJ22u8z4H6JBqas6RYkj1C",
        clientId: "98066c05-bbf2-4b6e-a662-e7e96d2de043",
        userAgent: "userAgent=LG-tv-3.0",
      };
    case DS.PlatformType.ps4:
    case DS.PlatformType.ps5:
      return {
        clientSecret: "KNzDfoFHIFuuhZ4KMkevrOk0FIog16Wxz2kyvXEK",
        clientId: "98066c90-cfa7-4971-96fa-fb1a0210fdd6",
        userAgent: "userAgent=PlayStation-tv-3.0",
      };
    case DS.PlatformType.tizen:
    default:
      return {
        clientSecret: "gVF7hFScJrDGwWu9uzu0mYdlKXxBKASczO2Q6K3y",
        clientId: "94efc52c-f55f-4c40-84fc-b4b5bd7de3ca",
        userAgent: "userAgent=Tizen-tv-3.0",
      };
  }
}

const PROD = {
  RTBF: {
    ...platformConfig(),
    authServerUrl: "https://auth-service.rtbf.be",
    apiServerUrl: "https://bff-service.rtbf.be",
    apiVersion: "v1.23",
    platform: "TIZEN",
    ramServerUrl: "https://aws-service.rtbf.be/prd",
    pairingUrl: "https://auvio.rtbf.be/connect",
  },
  RTBFU2C: {
    apiServerUrl: "https://u2c-service.rtbf.be/auvio",
  },
  REDBEE: {
    customer: "RTBF",
    businessUnit: "Auvio",
    exposureBaseUrl: "https://exposure.api.redbee.live",
    appName: "player_auvio",
    muxKey: "patvvpcoptmck9ppdv4cl31bn",
  },
  GIGYA: {
    dataCenter: "eu1.gigya.com",
    apiKey: "4_Ml_fJ47GnBAW6FrPzMxh0w", // smart TV (tizen, ps4, ...)	4_CuE2JhuHSypbU4mWRAsaIw, Web	4_0p0V_Rjv1UlkGNMzoFUnSQ
    clientId: "KlpDutRNVEFOa0yri1zcI2E1",
    clientSecret: "-UvjHSR-UtvEcp9RbStz-Pt0aJG70PZYGoPN9Uvbu2nvxsMmS3zl8VadEgBJp1-of_5FYTTliBzx3CmGFgJ0hA",
    pairingUrl: "login.auvio.rtbf.be",
  },
  DIDOMI: {
    authServerUrl: "https://didomi.rtbf.be/v1/sessions",
    apiServerUrl: "https://didomi.rtbf.be",
    apiKey: "f2e95fd4-fbc1-4bf6-aed9-15d090319b78",
    apiSecretKey: "XNyha3CbzdCDXPhZhCefexUGyyn9dT9jg3GfkdjKXpeb83J67ZbRpaTPkpcYgnCM",
    organization_id: "rtbf-radio",
    APIKey: "f7bf9c90-0b89-4d05-8ba9-5bad72f1c4d7",
    NoticeId: "R3BtfgZY",
  },
  GTAG: {
    id: "G-KFX7DSQW39",
    debug: false,
  },
  SNOWPLOW: {
    eventServerUrl: "https://prod.collector.gdd.rtbf.be/com.snowplowanalytics.snowplow/tp2",
    baseApplicationUrl: "https://auvio.rtbf.be",
  },
  ELASTIC: {
    apiServerUrl: "https://rtbf-observability.apm.europe-west1.gcp.cloud.es.io",
  },
};

const UAT: Config = deepCopyObject(PROD);
UAT.RTBF.authServerUrl = "https://auth-service.uat.rtbf.be";
UAT.RTBF.apiServerUrl = "https://bff-service.uat.rtbf.be";
UAT.RTBFU2C.apiServerUrl = "https://u2c-service.uat.rtbf.be/auvio";
UAT.GIGYA.apiKey = "4_LzZCp_Y3L2daR76Oq2OiZA";
UAT.GIGYA.clientId = "RrFXVO4HdgDsoN7D639HpCEY";
UAT.GIGYA.clientSecret = "OljZ7qqgK2lDLl2APtbmBeEWitFp9fLY9DBnfxfOtXsrJvb6U-ksF5SNOvlSX3gn4Ob6w6vH0gVk4GOH-jTArQ";
UAT.GIGYA.pairingUrl = "login.auvio.uat.rtbf.be";
UAT.REDBEE.muxKey = "7f6kb0u2vrnk62if7p9essut5";
UAT.GTAG.debug = true;
UAT.SNOWPLOW.eventServerUrl = "https://dev.collector.gdd.rtbf.be/com.snowplowanalytics.snowplow/tp2";
UAT.SNOWPLOW.baseApplicationUrl = "https://auvio.uat.rtbf.be";
UAT.RTBF.ramServerUrl = "https://aws-service.rtbf.be/uat";
UAT.RTBF.pairingUrl = "https://auvio.uat.rtbf.be/connect";

const Configs: { [key in Env]: Config } = {
  PROD,
  UAT,
};

export function env(): Env {
  switch (_BACKEND_TARGET_) {
    // a preview environment is any other branch that isn't main (the production branch)
    case "uat":
      return "UAT";
    case "prod":
      return "PROD";

    // finally if it's not defined (or "development" which should never happen on Vercel),
    // either we made a build (and we choose to target PROD) - or we are running a next run dev, and therefore UAT
    default:
      return process.env.NODE_ENV !== "production" ? "UAT" : "PROD";
  }
}

export function Config(): Config {
  return Configs[env()];
}
