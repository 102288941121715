import "./categoryWidgetView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GenericPage } from "../../../pages/generic/genericPage";
import { openKids } from "../../../pages/onBoarding/onBoardingPage";
import { CategoryCard, CtaCard, Widget, WidgetCategoryList } from "../../../utils/rtbf/models";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { MoreContentTile } from "../../tiles/moreContent/moreContent";
import { WidgetView } from "../widgetView";

class CategoryTileView extends AcceptsMouseFocusView {
  constructor(category: CategoryCard) {
    super(category.id, "tile");
    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileCover img cardContainer",
      style: {
        backgroundImage: `url(${category.illustration?.s})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    const titleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileTitle",
    });

    Config.locale$.didChange(
      () => {
        // when local changes, we reset our text - which is dependent on this locale
        titleElement.innerText = `${category.label}`;
      },
      this,
      true
    );
  }
}

type WidgetModel = CtaCard | CategoryCard;
/**
 * Category widget, built by the GenericPage.
 * @param widget The category widget.
 */
export class CategoryWidgetView extends WidgetView {
  focusTracker?: FocusTracker;
  protected _list: DS.IListComponent<WidgetModel, CategoryTileView | MoreContentTile>;

  constructor(widget: Widget) {
    super(widget.id, "categoryWidget");
    this._setAnalytics(widget);
    // Widget title
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });
    this._addSubtitleIfAvailable(widget);
    // Widget list of content
    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource(this._content(widget))),
        viewFactory: card => {
          switch (card.resourceType) {
            case "CATEGORY":
              return new CategoryTileView(card);
            case "CTA":
              return new MoreContentTile(card);
          }
        },
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          Log.app.info("This card is selected: ", card);
          if (card.path === "/kids") {
            openKids();
          } else {
            navigationStack.pushPage(new GenericPage(card.path));
          }
          super._trackCardAnalytics({
            gtag: {
              card_order: index,
              category_id: card.id,
              category_name: card.label,
            },
            card: { ...card, order: index },
          });
          return true;
        },
      },
      list => {
        this.focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this.focusTracker.onRelease);
        list.visibleRange$.didChange(
          (range, previousRange) => this._snowplowTracking(range, this._list, previousRange),
          this,
          true
        );
      }
    );
  }

  /**
   * Fetch category list and return it, with a CTA if available, if contentPath is defined
   *
   * @param {Widget} widget
   * @return {Promise<(CtaCard | CategoryCard)[]>} List of items
   */
  private async _content(widget: Widget): Promise<WidgetModel[]> {
    try {
      const content: (CtaCard | CategoryCard)[] =
        widget.contentPath !== undefined ? (await APIAuvio.widget(widget.contentPath, WidgetCategoryList)).content : [];
      if (widget.cta !== undefined && widget.cta !== null) {
        content.push(widget.cta);
      }
      return content;
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }
}
